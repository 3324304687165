import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import OpeningBanner from "../components/opening-banner";
import ThreeImageSection from "../components/three-image-section";
import Reviews from "../components/reviews";
import PriceListSection from "../components/price-list-section";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";


const MetalShedsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "metal-sheds" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        metalShedsPageFieldGroups {
          metalShedsBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          metalShedsTextSection {
            heading
            description
          }
          metalShedsFaqSection {
            heading
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          metalShedsTwoColumnSection {
            leftColumn {
              description
              heading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          metalShedsServiceSection {
            heading
            description
            cta {
              title
              target
              url
            }
            images {
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          metalShedsCtaSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
          }
          metalShedsReviewsSection {
            heading
          }
          metalShedsOpeningHoursSection {
            heading
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, metalShedsPageFieldGroups },
  } = data;

  const {
    metalShedsBannerSection,
    metalShedsCtaSection1,
    metalShedsFaqSection,
    metalShedsOpeningHoursSection,
    metalShedsReviewsSection,
    metalShedsServiceSection,
    metalShedsTextSection,
    metalShedsTwoColumnSection,
  } = metalShedsPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Metal Sheds",
        item: {
          url: `${siteUrl}/metal-sheds`,
          id: `${siteUrl}/metal-sheds`,
        },
      },
    ],
  };

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.topsgardenbuildings.com/metal-sheds`,
    image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.topsgardenbuildings.com/metal-sheds`,
    sku: `https://www.topsgardenbuildings.com/metal-sheds`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
    name: "Tops Garden Buildings",
    url: "https://www.topsgardenbuildings.com",
    brand: {
      "@type": "Organization",
      logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Tops Garden Buildings",
      url: "https://www.topsgardenbuildings.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: "125",
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.topsgardenbuildings.com",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.9",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Tops Garden Buildings",
        sameAs: "https://www.topsgardenbuildings.com",
      },
    },
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/metal-sheds`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {metalShedsBannerSection &&
            !checkPropertiesForNull(metalShedsBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  metalShedsBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={metalShedsBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={metalShedsBannerSection?.description}
                  />
                }
                ctaButton={metalShedsBannerSection?.cta1}
                image={metalShedsBannerSection?.image.node}
              />
            )}

          {metalShedsTextSection &&
            !checkPropertiesForNull(metalShedsTextSection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={4}>
                      <h2 className=" fs-1 mb-4 text-primary  ">
                        {metalShedsTextSection?.heading}
                      </h2>
                    </Col>
                    <Col lg={8}>
                      <SafeHtmlParser
                        htmlContent={metalShedsTextSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {metalShedsTwoColumnSection &&
            !checkPropertiesForNull(metalShedsTwoColumnSection, [
              "leftColumn",
            ]) && (
              <PriceListSection
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={metalShedsTwoColumnSection.leftColumn?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={
                      metalShedsTwoColumnSection.leftColumn?.description
                    }
                  />
                }
                imageHeight="25rem"
                image={
                  metalShedsTwoColumnSection.leftColumn?.image?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={
                  metalShedsTwoColumnSection.leftColumn?.image?.node?.altText
                }
              />
            )}

          {metalShedsServiceSection &&
            !checkPropertiesForNull(metalShedsServiceSection, [
              "heading",
            ]) && (
              <ThreeImageSection
                title={metalShedsServiceSection?.heading}
                text={
                  <div className="text-white">
                    <SafeHtmlParser
                      htmlContent={metalShedsServiceSection?.description}
                    />
                  </div>
                }
                imageHeight="18rem"
                images={metalShedsServiceSection?.images}
                ctaButton={metalShedsServiceSection.cta}
              />
            )}

          <Faq
            imageHeight="25rem"
            page="Home Offices"
            image={
              metalShedsFaqSection?.image?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            imageAlt={metalShedsFaqSection?.image?.node?.altText}
            heading={metalShedsFaqSection?.heading}
          />
          <Reviews
            topPadding="pt-5 pt-md-0"
            title={metalShedsReviewsSection?.heading}
          />

          <OpeningBanner heading={metalShedsOpeningHoursSection?.heading} />

          <ShowroomSection
            title={metalShedsCtaSection1?.heading}
            description={
              <SafeHtmlParser
                htmlContent={metalShedsCtaSection1?.description}
              />
            }
            ctaButton={metalShedsCtaSection1?.cta}
          />
        </Layout>
      </div>
    </>
  );
};

export default MetalShedsPage;
